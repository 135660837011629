import xss from 'xss'

export const getText = (html: string): string =>
  xss(html, {
    whiteList: {
      br: [],
    },
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['script'],
  })
