import CoreApi from '../../core-api'
import { EMPTY_EMAIL_ID, INVALID_EMAIL_ID, isNotEmptyEmailId } from '../../../../utils/utils'
import _ from 'lodash'
import RemoteApi from '../../../../panels/commons/remote-api'
import {
  EmailConfig as DomainEmailConfig,
  EmailResponse,
  EmptyState,
  Form as DomainForm,
  FormEmailSettings,
  SendTo,
  UserEmail,
} from '@wix/ambassador-wix-form-builder-web/http'
import { withBi } from '../../decorators'
import { handleError } from '../../../forms-editor-app/monitoring'
import { EVENTS } from '../../../../constants/bi'
import { emptyStateValue, getEmptyStateFromSendToDefinition, getRecipients } from './utils'
import { v4 as uuid } from 'uuid'
import Experiments from '@wix/wix-experiments'

export type GetEmailsResponse = {
  email: string
  emailId: string
  failedToSave?: boolean
}[]

export default class EmailsNotificationsApi {
  private biLogger: BILogger
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private shouldUseNewEmailSettings: boolean
  private experiments: Experiments

  constructor(boundEditorSDK, coreApi: CoreApi, remoteApi, { biLogger, experiments }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.shouldUseNewEmailSettings = null
    this.experiments = experiments
  }

  private async _shouldEnableEmailSettings(key) {
    if (this.shouldUseNewEmailSettings === null) {
      this.shouldUseNewEmailSettings = await this.coreApi.isExperimentEnabledForOwner(key)
    }

    return this.shouldUseNewEmailSettings
  }

  public async getEmails(emailIds: string[]) {
    if (!emailIds.length) {
      return []
    }
    const validEmailIds = _.filter(emailIds, (emailId) => emailId !== INVALID_EMAIL_ID)
    const isThereNonEmptyEmail = _.some(validEmailIds, isNotEmptyEmailId)

    if (!isThereNonEmptyEmail) {
      return validEmailIds.map((emailId) => ({ emailId, email: '' }))
    }

    return this.remoteApi.getEmailsById(validEmailIds)
  }

  private _getSelectedUsersFromOtherEmails(
    otherEmails: EmailResponse[],
    siteUsersData: UserEmail[],
    inboxOptOut: boolean,
    emptyState: EmptyState,
  ) {
    if (inboxOptOut || !otherEmails || emptyStateValue(emptyState)) {
      return null
    }
    const siteUsersEmails = _.map(siteUsersData, (user) => user.email)
    const emails = _.map(otherEmails, (otherEmail) => otherEmail.email)

    const hasOtherEmails = _.some(emails, (email) => !_.includes(siteUsersEmails, email))
    if (hasOtherEmails) {
      return null
    }

    return _(siteUsersData)
      .filter((user) => _.includes(emails, user.email))
      .map((user) => user.userId)
      .value()
  }

  private _getEntitiesFromSettings(emailSettings: FormEmailSettings) {
    const sendTo = emailSettings?.sendTo
    const emptyState = emailSettings?.emptyState

    const emailIds = sendTo?.emails?.emailIds ?? (emptyState === EmptyState.EMAILS ? [] : undefined) // TODO: remove once specs.crm.FormsBizEmailsApiUpgrade is merged
    const emails = sendTo?.emails?.emails ?? (emptyState === EmptyState.EMAILS ? [] : undefined)
    const selectedSiteUserIds =
      sendTo?.users?.userIds ?? (emptyState === EmptyState.USERS ? [] : undefined)
    const isOwner = sendTo?.owner

    if ((_.isArray(emailIds) || _.isArray(emails)) && _.isArray(selectedSiteUserIds)) {
      throw Error('emails and users should not be configured together')
    }

    return {
      emails: emails?.map((email) => ({ emailId: uuid(), email })),
      emailIds,
      selectedSiteUserIds,
      isOwner,
      emptyState,
    }
  }

  private async _selectedEmailsAndUsers({
    emailSettings,
    inboxOptOut,
    siteUsersData,
    withUpgradedEmails,
  }: {
    emailSettings: FormEmailSettings
    inboxOptOut: boolean
    siteUsersData: UserEmail[]
    withUpgradedEmails: boolean
  }) {
    const owner = _.find(siteUsersData, (user) => user.isOwner)
    const {
      emailIds,
      selectedSiteUserIds,
      emptyState,
      isOwner,
      emails: emailsFromSettings,
    } = this._getEntitiesFromSettings(emailSettings)

    if (withUpgradedEmails) {
      if (_.isArray(emailsFromSettings)) {
        let emails = emailsFromSettings

        if (isOwner) {
          emails = [{ emailId: '', email: owner.email }, ...emails]
        }

        const selectedSiteUsersIdsFromOtherEmails = this._getSelectedUsersFromOtherEmails(
          emails,
          siteUsersData,
          inboxOptOut,
          emptyState,
        )

        if (selectedSiteUsersIdsFromOtherEmails) {
          return {
            emails: null,
            selectedSiteUsersIds: selectedSiteUsersIdsFromOtherEmails,
          }
        }
        return { emails, selectedSiteUsersIds: null }
      }
    } else {
      if (_.isArray(emailIds)) {
        let emails = await this.getEmails(emailIds)

        if (isOwner) {
          emails = [{ emailId: '', email: owner.email }, ...emails]
        }

        const selectedSiteUsersIdsFromOtherEmails = this._getSelectedUsersFromOtherEmails(
          emails,
          siteUsersData,
          inboxOptOut,
          emptyState,
        )

        if (selectedSiteUsersIdsFromOtherEmails) {
          return {
            emails: null,
            selectedSiteUsersIds: selectedSiteUsersIdsFromOtherEmails,
          }
        }
        return { emails, selectedSiteUsersIds: null }
      }
    }

    const ownerWithoutUsers = isOwner && !_.isArray(selectedSiteUserIds)

    return {
      emails: null,
      selectedSiteUsersIds: ownerWithoutUsers ? [owner.userId] : selectedSiteUserIds,
    }
  }

  @withBi({ endEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public async setEmail(
    componentRef: ComponentRef,
    {
      emailIndex,
      newEmail,
      currentEmails,
    }: {
      emailIndex: number
      newEmail: string
      currentEmails: GetEmailsResponse
    },
    _biData = {},
  ): Promise<GetEmailsResponse> {
    try {
      if (_.get(currentEmails, `[${emailIndex}].email`) === newEmail) {
        return currentEmails
      }

      let newEmailId = null

      if (!_.isEmpty(newEmail)) {
        const { emailId } = await this.remoteApi.insertEmail(newEmail)
        newEmailId = emailId
      } else {
        if (emailIndex === 0) {
          newEmailId = EMPTY_EMAIL_ID
        }
      }

      const newValidEmails = _.filter(
        _.cloneDeep(currentEmails),
        (email) => email.emailId !== INVALID_EMAIL_ID,
      )
      newValidEmails[emailIndex] = { emailId: newEmailId, email: newEmail }
      const emailIds = newValidEmails.map((email) => email.emailId)
      await this.coreApi.setComponentConnection(componentRef, { emailIds }, false)

      return newValidEmails
    } catch (err) {
      handleError(err, {
        tags: { funcName: 'settings.notifications.setEmail' },
        extra: { message: 'Failed to setEmail' },
      })
      const newEmails = _.cloneDeep(currentEmails)
      newEmails[emailIndex] = { emailId: INVALID_EMAIL_ID, email: newEmail, failedToSave: true }
      return newEmails
    }
  }

  public async deleteInactiveEmails(
    componentRef: ComponentRef,
    emails: GetEmailsResponse,
    emailsLimit: number,
  ): Promise<GetEmailsResponse> {
    if (emails.length <= emailsLimit) {
      return emails
    }

    const filteredEmails = _.filter(
      emails,
      (email) =>
        email !== null &&
        _.get(email, 'emailId') !== EMPTY_EMAIL_ID &&
        _.get(email, 'emailId') !== '',
    )

    const updatedEmails = filteredEmails.slice(0, emailsLimit)
    const emailIds = updatedEmails.map((email) => email.emailId)
    await this.coreApi.setComponentConnection(componentRef, { emailIds }, false)

    return updatedEmails
  }

  public isEmailNotificationOnBizEnabled() {
    if (this.coreApi.isADI()) {
      return this._shouldEnableEmailSettings('specs.crm.FormsBizNewEmailsSettingsAdi')
    }

    return this._shouldEnableEmailSettings('specs.crm.FormsBizNewEmailsSettings')
  }

  public async createEmailConfigFromConfig({
    componentRef,
    componentConnection,
  }: {
    componentRef?: ComponentRef
    componentConnection?: ComponentConnection
  }): Promise<DomainEmailConfig> {
    const {
      sendTo: { emails, owner, users },
    } = await this.createEmailsSettingsFromConfig({
      componentRef,
      componentConnection,
      withUpgradedEmails: false, // at the moment this is not supported at publish site (this.experiments.enabled('specs.crm.FormsBizEmailsApiUpgrade'))
    })
    if (emails && owner) {
      return {
        sendToOwnerAndEmails: {
          emailIds: emails.emailIds,
        },
      }
    }
    if (emails) {
      return {
        sendToEmails: {
          emailIds: emails.emailIds,
        },
      }
    }

    if (owner) {
      return {
        sendToOwner: {},
      }
    }

    if (users) {
      return {
        sendToContributors: {
          userIds: users.userIds,
        },
      }
    }
  }

  public async createEmailsSettingsFromConfig({
    componentRef,
    componentConnection,
    withUpgradedEmails,
  }: {
    componentRef?: ComponentRef
    componentConnection?: ComponentConnection
    withUpgradedEmails: boolean
  }): Promise<FormEmailSettings> {
    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = connection?.config
    const createSendTo = async (): Promise<SendTo> => {
      const { inboxOptOut, emailIds, emailId, secondEmailId, selectedSiteUsersIds } = config || {}
      const actualEmailId = emailIds || [emailId, secondEmailId]
      const recipients = getRecipients(actualEmailId)

      if (!_.isBoolean(inboxOptOut) || inboxOptOut) {
        const emailsObj =
          recipients.sendToOwner && !recipients.emailIds.length
            ? {}
            : withUpgradedEmails
            ? {
                emails: {
                  emails: (await this.getEmails(recipients.emailIds)).map((e) => e.email),
                },
              }
            : {
                emails: {
                  emailIds: recipients.emailIds,
                },
              }

        return {
          ...(recipients.sendToOwner ? { owner: {} } : {}),
          ...emailsObj,
        }
      } else {
        if (!selectedSiteUsersIds) {
          if (recipients.sendToOwner) {
            return {
              owner: {},
            }
          }
        }

        return {
          users: {
            userIds: selectedSiteUsersIds || [],
          },
        }
      }
    }
    const sendTo = await createSendTo()
    return {
      sendTo,
      emptyState: getEmptyStateFromSendToDefinition(sendTo, withUpgradedEmails),
    }
  }

  public async getEmailsAndSiteUsers(
    componentRef: ComponentRef,
    componentConnection?: ComponentConnection,
    form?: DomainForm,
  ): Promise<{
    emails?: EmailResponse[]
    siteUsersData: UserEmail[]
    selectedSiteUsersIds?: string[]
    inboxOptOut?: boolean
  }> {
    const formSchema =
      form || (await this.coreApi.settings.getForm(componentRef, componentConnection))
    const withUpgradedEmails = this.experiments.enabled('specs.crm.FormsBizEmailsApiUpgrade')
    const isEmailNotificationsOnBiz = await this.isEmailNotificationOnBizEnabled()
    let emailSettingsSchema = formSchema?.formMetadata?.emailSettings
    if (emailSettingsSchema && !isEmailNotificationsOnBiz) {
      this.biLogger.log({
        evid: 1708,
        form_comp_id: await this.coreApi.getFormId(componentRef, componentConnection),
      })
    }

    const connection =
      componentConnection || (await this.coreApi.getComponentConnection(componentRef))
    const config = _.get(connection, 'config')

    if (isEmailNotificationsOnBiz) {
      let inboxOptOut = config.inboxOptOut

      if (!emailSettingsSchema) {
        emailSettingsSchema = await this.createEmailsSettingsFromConfig({
          componentRef,
          componentConnection,
          withUpgradedEmails,
        })
        await this.remoteApi.formServiceClient.updateEmailSettings({
          publicId: formSchema.id,
          revision: formSchema.revision,
          emailSettings: emailSettingsSchema,
          inboxNotificationOptOut: !!inboxOptOut,
        })
      } else {
        inboxOptOut = formSchema?.attributes?.afterSubmitSettings?.inboxNotificationOptOut?.enabled
      }
      const siteUsersData = await this.remoteApi.getSiteUsersData()

      const emailsAndUsers = await this._selectedEmailsAndUsers({
        emailSettings: emailSettingsSchema,
        inboxOptOut,
        siteUsersData,
        withUpgradedEmails,
      })
      return {
        ...emailsAndUsers,
        inboxOptOut,
        siteUsersData,
      }
    }

    const actualEmailsIds: string[] = config.emailIds || [config.emailId, config.secondEmailId]

    const [emails, siteUsersData] = await Promise.all([
      this.getEmails(actualEmailsIds),
      this.remoteApi.getSiteUsersData(),
    ])

    const owner = _.find(siteUsersData, (user) => user.isOwner)

    const [firstEmail, ...restOfEmails] = emails
    let actualFirstEmail = firstEmail

    if (_.get(firstEmail, 'emailId') !== EMPTY_EMAIL_ID && !_.get(firstEmail, 'email')) {
      actualFirstEmail = { emailId: null, email: _.get(owner, 'email', '') }
    }

    return {
      emails: [actualFirstEmail, ...restOfEmails.filter((email) => email.email)],
      siteUsersData,
      selectedSiteUsersIds: config.selectedSiteUsersIds,
      inboxOptOut: config.inboxOptOut,
    }
  }

  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.VALUE_UPDATED })
  public setSelectedSiteUsersIds(
    formComponentRef: ComponentRef,
    selectedSiteUsersIds: string[],
    _biData = {},
  ) {
    return this.coreApi.setComponentConnection(
      formComponentRef,
      { inboxOptOut: false, selectedSiteUsersIds },
      false,
    )
  }
  @withBi({ startEvid: EVENTS.PANELS.settingsPanel.UPDATE_EMAIL_NOTIFICATIONS_OPTION })
  public updateInboxOptOutSelection(formComponentRef: ComponentRef, optOut: boolean, _biData = {}) {
    return this.coreApi.setComponentConnection(formComponentRef, { inboxOptOut: optOut })
  }
}
