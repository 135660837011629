import {
  ActionTriggersServer,
  Rule as ActionTriggersRule,
} from '@wix/ambassador-action-triggers-server/http'
import { WixContactsWebapp } from '@wix/ambassador-wix-contacts-webapp/http'
import {
  ContactsFieldsApp,
  FieldType,
  ExtendedField,
  FieldDataType,
} from '@wix/ambassador-contacts-fields-app/http'
import {
  ContactField,
  DataType,
  ExternalFieldMapping,
  MigrateFieldsRequest,
  MigrateFieldsResponse,
} from '@wix/ambassador-wix-contacts-webapp/types'
import Experiments from '@wix/wix-experiments'
import _ from 'lodash'
import { FORMS_APP_DEF_ID } from '../../constants'
import { CustomField, CustomFieldResponse } from '../../constants/field-types'
import { PremiumRestriction } from '../../constants/premium'
import { ConvertEmailRequest, ConvertEmailResponse } from '../../types/domain-types'
import { parseInstance } from '../../utils/utils'
import { ASCEND_PLAN, MAP_PRODUCT_ID_TO_ASCEND_PLAN } from './constants/ascend'
import {
  FORMS_SERVERLESS,
  FORMS_SERVERLESS_OLD,
  PLATFORMIZED_PAYMENTS_URL,
  PREMIUM_STORE_URL,
} from './constants/external-endpoints'
import { FormsServiceAmbassadorClient } from './formsServiceAmbassadorClient'
import {
  EmailResponse,
  PublishSiteRequest,
  UserEmail,
} from '@wix/ambassador-wix-form-builder-web/types'
import { ContactsLabelsApp } from '@wix/ambassador-contacts-labels-app/http'

export const Method = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
}

export const FEATURES = {
  CUSTOM_STEPS: 'custom_steps',
  SUBMISSIONS_PER_MONTH: 'submissions_per_month',
  CUSTOM_FIELDS: 'custom_fields',
  CUSTOMIZABLE_FORMS: 'customizable_forms',
  ACCEPT_PAYMENTS: 'accept_payments_on_form',
  UPLOAD_FIELD: 'file_upload_field',
  DOWNLOAD_FILE: 'file_downloads',
  SIGNATURE_FIELD: 'signature_field',
  EMAILS: 'max_emails_on_form',
  RULES: 'forms_conditional_forms',
}

const ASCEND_PRODUCT_ID = '73988963-5f5f-4f61-b6a1-fd004df31b00'
const callerId = { 'X-Wix-Client-Artifact-Id': 'wix-form-builder' }
export const CONTACT_FORM_ACTIVITY = 'contact/contact-form'
export const SUBSCRIPTION_FORM_ACTIVITY = 'contact/subscription-form'
const WIX_FORMS_ACTIVITY = 'form/form'

const getService = (appInstance) => ({
  contacts: () => {
    const contactsServices = WixContactsWebapp('/wix-contacts-webapp')
    const contactLabels = ContactsLabelsApp('/_api/contacts/')
    const contactsFieldsServices = ContactsFieldsApp('/_api/contacts/')
    return {
      labels: contactsServices.ContactsLabelsService()({ Authorization: appInstance, ...callerId }),
      labelsV4: contactLabels.ContactLabelsServiceV4()({ Authorization: appInstance, ...callerId }),
      schema: contactsServices.ContactsSchemaService()({ Authorization: appInstance, ...callerId }),
      extendedFields: contactsFieldsServices.ContactExtendedFieldsServiceV4()({
        Authorization: appInstance,
        ...callerId,
      }),
    }
  },
  actionTriggers: () => {
    const url = '/_api/action-triggers-server'
    return {
      triggers: ActionTriggersServer(url).ActionTriggersIntegratorService()({
        Authorization: appInstance,
        ...callerId,
      }),
      backoffice: ActionTriggersServer(url).AutomationsBackofficeService()({
        Authorization: appInstance,
        ...callerId,
      }),
    }
  },
})

export default class RemoteApi {
  private boundEditorSDK: BoundEditorSDK
  private experiments: Experiments
  private ravenInstance
  private httpClient: IHttpClient
  public formServiceClient: FormsServiceAmbassadorClient

  constructor({ boundEditorSDK, experiments, ravenInstance, formServiceClient, httpClient }) {
    this.boundEditorSDK = boundEditorSDK
    this.experiments = experiments
    this.ravenInstance = ravenInstance
    this.formServiceClient = formServiceClient
    this.httpClient = httpClient
  }

  private async _getAppInstance() {
    return this.boundEditorSDK.info.getAppInstance()
  }

  public async getFormsRules(externalEvent) {
    const hasConditions = (rule) => {
      const conditions = _.get(rule, 'trigger.condition.allCondition.conditions')
      return conditions && conditions.length
    }
    const appInstance = await this._getAppInstance()

    const res = await getService(appInstance)
      .actionTriggers()
      .triggers.searchRules({ eventType: [externalEvent, WIX_FORMS_ACTIVITY] })

    const rules = res.rules
    const externalRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === externalEvent,
    )
    const wixFormsRules = rules.filter(
      (role) => _.get(role, 'trigger.activityType') === WIX_FORMS_ACTIVITY,
    )
    const wixFormsAllForms = wixFormsRules.filter((rule) => !hasConditions(rule))
    const wixFormsSpecificForms = wixFormsRules.filter((rule) => hasConditions(rule))
    return { externalRules, wixFormsAllForms, wixFormsSpecificForms }
  }

  public async migrateRule(ruleId: string, formIds?: string[], schema?: string) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .backoffice.migrateForms({ formIds, ruleId, schema })
  }

  public async disableRule(rule: ActionTriggersRule) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .actionTriggers()
      .triggers.updateRule({ rule: { ...rule, status: 'suspended' } })
  }

  public async getEmailsById(emailIds = []): Promise<EmailResponse[]> {
    const emailsResponse = await this.formServiceClient.getEmailsByEmailIds(emailIds)

    return emailIds.map((emailId) => {
      const emailFromResponse = _.find(emailsResponse.emails, { emailId })
      return emailFromResponse || { emailId, email: '' }
    })
  }

  public async getSiteUsersData(): Promise<UserEmail[]> {
    return (await this.formServiceClient.getSiteUsers()).emails
  }

  public async insertEmail(email) {
    return this.formServiceClient.addEmail(email)
  }

  private convertV1IntoDomainLabel(label) {
    return {
      id: label.id,
      type: label.type,
      name: label.details.name,
      isV4: false,
    }
  }

  private convertV4IntoDomainLabel(label) {
    return {
      id: label.legacyId,
      key: label.key,
      type: label.labelType,
      name: label.displayName,
      isV4: true,
    }
  }

  private async _createTag(tagName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.create({ details: { name: tagName } })
      .then(({ label }) => this.convertV1IntoDomainLabel(label))
  }

  private async _createTagV4(tagName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labelsV4.findOrCreateLabel({ displayName: tagName })
      .then(({ label }) => this.convertV4IntoDomainLabel(label))
  }

  public async createTag(tagName): Promise<DomainContactLabel> {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateContactLabelsV4')
      ? this._createTagV4(tagName)
      : this._createTag(tagName)
  }

  public async _updateTag(tagId, newName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.update({ id: tagId, details: { name: newName } })
      .then(({ label }) => this.convertV1IntoDomainLabel(label))
  }

  public async _updateTagV4(key, newName) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labelsV4.updateLabel({ label: { key, displayName: newName } })
      .then(({ label }) => this.convertV4IntoDomainLabel(label))
  }

  public async updateTag(tagIdentifier, newName): Promise<DomainContactLabel> {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateContactLabelsV4')
      ? this._updateTagV4(tagIdentifier, newName)
      : this._updateTag(tagIdentifier, newName)
  }

  // remove after merge specs.crm.FormsEditorMigrateContactLabelsV4
  private async _getLabels(): Promise<DomainContactLabel[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labels.list({})
      .then((data) => {
        return _.map(data.labels, (label) => this.convertV1IntoDomainLabel(label))
      })
  }

  private async _getLabelsV4(): Promise<DomainContactLabel[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .labelsV4.listLabels({})
      .then((data) => {
        return _.map(data.labels, (label) => this.convertV4IntoDomainLabel(label))
      })
  }

  public async getLabels(): Promise<DomainContactLabel[]> {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateContactLabelsV4')
      ? this._getLabelsV4()
      : this._getLabels()
  }

  private _customFieldV4Mapper = (field: ExtendedField) => ({
    id: field.legacyId,
    key: field.key,
    name: field.displayName,
    fieldType: field.dataType,
  })

  private _customFieldV1Mapper = (field: ContactField) => {
    const mappingOldToNewTypes = {
      [DataType.Text]: FieldDataType.TEXT,
      [DataType.Number]: FieldDataType.NUMBER,
      [DataType.URL]: FieldDataType.URL,
      [DataType.Date]: FieldDataType.DATE,
    }
    return {
      id: field.id,
      name: field.details.name,
      key: undefined,
      fieldType: mappingOldToNewTypes[field.details.dataType],
    }
  }

  private async _getCustomFields(): Promise<CustomFieldResponse[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.listFields({})
      .then(({ fields }) =>
        fields
          .filter((field) => field.fieldType === 'USER_DEFINED')
          .map<CustomFieldResponse>(this._customFieldV1Mapper),
      )
      .catch(() => [])
  }

  private async _getCustomFieldsV4(): Promise<CustomFieldResponse[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .extendedFields.listExtendedFields({})
      .then(({ fields }) =>
        fields
          .filter((field) => field.fieldType === FieldType.USER_DEFINED)
          .map<CustomFieldResponse>((field) => {
            return this._customFieldV4Mapper(field)
          }),
      )
      .catch(() => [])
  }

  public async getCustomFields(): Promise<CustomFieldResponse[]> {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateCustomFieldsV4')
      ? this._getCustomFieldsV4()
      : this._getCustomFields()
  }

  public async migrateFields(request: MigrateFieldsRequest): Promise<ExternalFieldMapping[]> {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .schema.migrateFields(request)
      .then((res: MigrateFieldsResponse) => {
        return res.migrated
      })
      .catch(() => [])
  }

  private async _createCustomField(field: CustomField) {
    const appInstance = await this._getAppInstance()
    const mappingNewToOldTypes = {
      [FieldDataType.TEXT]: DataType.Text,
      [FieldDataType.NUMBER]: DataType.Number,
      [FieldDataType.URL]: DataType.URL,
      [FieldDataType.DATE]: DataType.Date,
    }
    return getService(appInstance)
      .contacts()
      .schema.createField({
        details: { name: field.name, dataType: mappingNewToOldTypes[field.fieldType] },
      })
      .then((res) => this._customFieldV1Mapper(res.field))
  }

  private async _createCustomFieldV4(field: CustomField) {
    const appInstance = await this._getAppInstance()
    return getService(appInstance)
      .contacts()
      .extendedFields.findOrCreateExtendedField({
        displayName: field.name,
        dataType: field.fieldType,
      })
      .then((res) => this._customFieldV4Mapper(res.field))
  }

  public async createCustomField(field: CustomField) {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateCustomFieldsV4')
      ? this._createCustomFieldV4(field)
      : this._createCustomField(field)
  }

  private async _updateCustomFieldName(id: string, newName: string): Promise<void> {
    const appInstance = await this._getAppInstance()
    await getService(appInstance)
      .contacts()
      .schema.updateField({
        id,
        details: {
          name: newName,
        },
      })
  }

  private async _updateCustomFieldNameV4(key: string, newName: string): Promise<void> {
    const appInstance = await this._getAppInstance()
    await getService(appInstance)
      .contacts()
      .extendedFields.updateExtendedField({
        field: {
          key,
          displayName: newName,
        },
      })
  }

  public async updateCustomFieldName(tagIdentifier: string, newName: string): Promise<void> {
    return this.experiments.enabled('specs.crm.FormsEditorMigrateCustomFieldsV4')
      ? this._updateCustomFieldNameV4(tagIdentifier, newName)
      : this._updateCustomFieldName(tagIdentifier, newName)
  }

  public publishSite(data: PublishSiteRequest) {
    return this.formServiceClient.publishSite(data)
  }

  public addContactFormMapping(data) {
    return this.formServiceClient.addContactFormMapping(data)
  }

  public async editDraft(form) {
    try {
      return await this.formServiceClient.editDraft(form)
    } catch {
      return null
    }
  }

  public async getPremiumRestrictions(): Promise<{
    restrictions: PremiumRestriction
    currentAscendPlan: string
  }> {
    return this._platformizedRequest({
      url: FORMS_SERVERLESS.URL,
      endpoint: FORMS_SERVERLESS.ENDPOINT.RESTRICTIONS,
      method: Method.GET,
    })
  }

  public async getCurrentAscendPlan(): Promise<{ ascendPlan: string; isTopPremium: boolean }> {
    const msid = await this.boundEditorSDK.info.getMetaSiteId()
    const endpoint = `offering/${ASCEND_PRODUCT_ID}?msid=${msid}`
    let currentSubscriptionInfo

    try {
      const request = await this._platformizedRequest({
        url: PREMIUM_STORE_URL,
        endpoint,
        method: Method.GET,
      })
      currentSubscriptionInfo = _.get(request, 'currentSubscriptionInfo')
    } catch {
      currentSubscriptionInfo = {}
    }

    const productId = _.get(currentSubscriptionInfo, 'productId')

    const currentPlan = MAP_PRODUCT_ID_TO_ASCEND_PLAN[productId] || ASCEND_PLAN.FREE

    return { ascendPlan: currentPlan, isTopPremium: currentPlan === ASCEND_PLAN.UNLIMITED }
  }

  public async getConnectedPayments() {
    const appInstance = await this._getAppInstance()
    const instanceId = parseInstance(appInstance).instanceId
    const endpoint = `accounts/${FORMS_APP_DEF_ID}:${instanceId}/payment-methods`

    return this._platformizedRequest({
      url: PLATFORMIZED_PAYMENTS_URL,
      endpoint,
      method: Method.GET,
    })
  }

  public async convertContactFormEmails(
    convertContactFormEmailsRequest: ConvertEmailRequest,
  ): Promise<ConvertEmailResponse> {
    return this.formServiceClient.convertContactFormEmails(convertContactFormEmailsRequest)
  }

  private async _platformizedRequest({
    url,
    endpoint,
    method,
    data = undefined,
  }: {
    url: string
    endpoint: string
    method: string
    data?: any
  }) {
    try {
      const isTemplate = !(await this.boundEditorSDK.info.isSiteSaved())

      if (isTemplate) {
        return Promise.resolve()
      }

      const appInstance = await this._getAppInstance()

      const response = await this.httpClient.request({
        headers: { Authorization: appInstance, ...callerId },
        method,
        url: `${url}/${endpoint}`,
        data,
      })

      return _.get(response, 'data')
    } catch (err) {
      this.ravenInstance.setExtraContext({ url, endpoint, method, data })
      throw err
    }
  }
}
