import CoreApi from '../core-api'
import Experiments from '@wix/wix-experiments'
import RemoteApi from '../../../panels/commons/remote-api'
import _ from 'lodash'
import { CUSTOM_FIELD, CRM_TAGS, CRM_TYPES } from '../../../constants/crm-types-tags'
import { FormsFieldPreset } from '@wix/forms-common'
import { Classification, ContactField, DataType } from '@wix/ambassador-wix-contacts-webapp/http'
import { LabelType } from '@wix/ambassador-contacts-labels-app/types'
import { CustomFieldResponse } from '../../../constants/field-types'

const migrateCRMTypes = [CRM_TYPES.DATE, CRM_TYPES.WEBSITE]

const crmClassifier = {
  [CRM_TYPES.DATE]: {
    [CRM_TAGS.BIRTHDAY]: Classification.BIRTHDAY,
    [CRM_TAGS.ANNIVERSARY]: Classification.ANNIVERSARY,
    [CRM_TAGS.OTHER]: Classification.DATE,
  },
  [CRM_TYPES.WEBSITE]: {
    [CRM_TAGS.WORK]: Classification.WORK_WEBSITE,
    [CRM_TAGS.PERSONAL]: Classification.PERSONAL_WEBSITE,
    [CRM_TAGS.OTHER]: Classification.WEBSITE,
  },
}

export default class LazyMigrationsApi {
  private biLogger: any
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private remoteApi: RemoteApi
  private ravenInstance
  private experiments: Experiments

  constructor(
    boundEditorSDK,
    coreApi: CoreApi,
    remoteApi,
    { biLogger, ravenInstance, experiments },
  ) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.remoteApi = remoteApi
    this.ravenInstance = ravenInstance
    this.experiments = experiments
  }

  private _migrateContactField(componentRef: ComponentRef, contactField: ContactField) {
    const config: Partial<FormField> = {
      crmType: CUSTOM_FIELD,
      crmTag: null,
      customFieldId: null,
    }

    if (contactField) {
      const {
        id,
        fieldType,
        details: { name, dataType },
      } = contactField
      if (fieldType === 'BUILT_IN' && name === 'birthday') {
        config.crmType = CRM_TYPES.BIRTHDATE
        config.fieldType = FormsFieldPreset.BIRTHDAY
      }
      if (fieldType === 'USER_DEFINED') {
        config.customFieldId = id
        config.crmType = CUSTOM_FIELD
        config.customFieldName = name
        switch (dataType) {
          case DataType.Date:
            config.fieldType = FormsFieldPreset.GENERAL_DATE_PICKER
            break
          case DataType.URL:
            config.fieldType = FormsFieldPreset.GENERAL_URL
            break
        }
      }
    }

    return this.coreApi.setComponentConnection(componentRef, config)
  }

  public async applyContactMigration(
    forms: { ref: ComponentRef; connection: ComponentConnection }[],
  ) {
    const fields = await Promise.all(
      _.map(forms, (form) => this.coreApi.fields.getFieldsSortByXY(form.ref)),
    )
    const classifiedFields = _(fields)
      .flatten()
      .filter(({ crmType }) => _.includes(migrateCRMTypes, crmType))
      .map(({ componentRef, crmTag, crmType }) => ({
        classification: crmClassifier[crmType][crmTag],
        externalId: componentRef.id,
        componentRef,
      }))
      .value()

    if (_.isEmpty(classifiedFields)) {
      return
    }

    const classifiedById = _.keyBy(classifiedFields, 'externalId')

    const contactsMigrationData = await this.remoteApi.migrateFields({
      externalFields: _.map(classifiedFields, ({ classification, externalId }) => ({
        classification,
        externalId,
      })),
    })

    await Promise.all(
      _.map(contactsMigrationData, ({ contactField, externalField }) => {
        const { externalId } = externalField
        return this._migrateContactField(classifiedById[externalId].componentRef, contactField)
      }),
    )
  }

  private _oldSystemLabelIdIntoNewLabelId(systemLabelId: string) {
    switch (systemLabelId) {
      case 'contacts-contacted_me':
        return 'contacts_server/contacted_me'
      case 'contacts-customers':
        return 'contacts_server/customers'
      default:
        return systemLabelId
    }
  }

  private async _getLabelKeysAndFormLabelKey(
    config: ComponentConfig,
    labels: DomainContactLabel[],
  ) {
    const { labelKeys, formLabelKey, labels: configLabels, formLabelId } = config
    if (!labelKeys) {
      return {
        labelKeys: configLabels?.map(
          (configLabel) =>
            labels.find(
              (label) =>
                label.id === configLabel ||
                (label.type === LabelType.SYSTEM &&
                  label.id === this._oldSystemLabelIdIntoNewLabelId(configLabel)),
            )?.key,
        ),
        formLabelKey: formLabelId && labels.find((label) => label.id === formLabelId)?.key,
      }
    }
    return { labelKeys, formLabelKey }
  }

  public async getLabelKeysAndFormLabelKey(config: ComponentConfig) {
    const labels = await this.remoteApi.getLabels()
    return this._getLabelKeysAndFormLabelKey(config, labels)
  }

  public async applyLabelsMigration(
    forms: { ref: ComponentRef; connection: ComponentConnection }[],
  ) {
    const formsWithConfig = await Promise.all(
      forms.map(async (form) => {
        const { config } = await this.coreApi.getComponentConnection(form.ref)
        return {
          formRef: form.ref,
          config,
        }
      }),
    )
    const formsWithMissingLabelKeys = formsWithConfig.filter(
      ({ config }) => !config.labelKeys && !config.isDummyForm,
    )
    if (formsWithMissingLabelKeys.length > 0) {
      const labels = await this.remoteApi.getLabels()
      if (labels) {
        formsWithMissingLabelKeys.forEach(async ({ config, formRef }) => {
          const configUpdates = await this._getLabelKeysAndFormLabelKey(config, labels)
          this.coreApi.setComponentConnection(formRef, configUpdates)
        })
      }
    }
  }

  private async _migrateCustomField(field: FormField, customFields: CustomFieldResponse[]) {
    const contactCustomField = _.find(customFields, { id: field.customFieldId })

    return (
      contactCustomField &&
      this.coreApi.setComponentConnection(field.componentRef, {
        customFieldKey: contactCustomField.key,
      })
    )
  }

  public async applyContactCustomFieldsV4Migration(
    forms: { ref: ComponentRef; connection: ComponentConnection }[],
  ) {
    const customFields = await this.remoteApi.getCustomFields()

    if (_.isEmpty(customFields)) {
      return
    }

    const fields = await Promise.all(
      _.map(forms, (form) => this.coreApi.fields.getFieldsSortByXY(form.ref)),
    )
    const fieldsToUpdate = _(fields)
      .flatten()
      .filter(
        ({ customFieldKey, customFieldId, crmType }) =>
          crmType === CUSTOM_FIELD && customFieldId && !customFieldKey,
      )
      .value()

    if (_.isEmpty(fieldsToUpdate)) {
      return
    }

    await Promise.all(
      _.map(fieldsToUpdate, (compRef) => this._migrateCustomField(compRef, customFields)),
    )
  }
}
